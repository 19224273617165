.game-header{
  position: relative;
  z-index: 990;

  .game-info{
    width: calc(100% - 30px);
    display: flex;
    color: #ffffff;
    justify-content: space-between;
    font-size: 18px;
    .menu-btn{
      width: 30%;
      margin-left: 30px;

      button.mute {
        margin-left: 30px;
        position: relative;
        top: 4px;

        @include tablet-down {
          margin-left: 10px;
        }
      }
    }
    .menu{
      position: absolute;
      z-index: 950;
      color: #000;
      button {
        display: block;
        width: 100%;
        text-align: left;

        &:hover{
          background-color: #000;
          color: #FFF;
        }
      }
    }
    .level-number{
      width: 40%;
      text-align: center;
      font-size: 16px;
      text-transform: uppercase ;
    }
    .score-lives{
      width: 30%;
      height: 50px;
      .score{
        font-size: 16px;
        clear: both;
        float: right;
      }
    }
    .lives{
      position: absolute;
      right: 30px;
      top: 20px;
      display: block;
      div{
        display: inline-block;
        margin-left: 10px;
        margin-top: 10px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 2px solid #FFFFFF;
        background: #FFF;
      }
      .dead{
        background: none;
      }
    }
  }
}