@font-face {
    font-family:"InputMonoCompressed Light";
    src:url("../fonts/InputMonoCompressed-Light.woff2") format("woff2"),url("../fonts/InputMonoCompressed-Light.woff");
    font-style:normal;font-weight:400;
}
@font-face {
    font-family:"Input Mono Compressed";
    src:url("../fonts/InputMonoCompressed-Light.woff2") format("woff2"),url("../fonts/InputMonoCompressed-Light.woff");
    font-style:normal;font-weight:400;
}

body{
  display: block;
  widows: 100vw;
  height: 100vh;
  overflow: hidden;
  /* font-family: Arial, Helvetica, sans-serif; */
  /* font-family: 'dogicabold', Arial, Helvetica, sans-serif; */
  background-color: black;
  color: white;
  font-family: InputMonoCompressed Light, sans-serif;
  text-transform: uppercase;;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 1.5;
  /* padding: 30px; */
  overflow-x: hidden !important;
}

li {
   margin:0;
   padding: 0;
   text-indent: 0;
   list-style-type: none;
}
#footer{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items:center;
    color: #FFFFFF;
    width: 96%;
    margin: 20px auto;
    margin-top: 40px;
    font-size: 12px;
}
#footer a{
  padding-top: 5px;
  display:block;
  color: #45F8ED;
}
#footer img{
  width: 125px;
  height: 28px;
}

// main app

div.App {
  div.background-video {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.05;

    video {
      max-width: 100vw;
      max-height: 100vh;
      display: block;
    }
  }
}

// type

h1, h2, h3, h4, h5 {
  font-weight: normal;
}

p {
  line-height: 1.5;
  a {
    color: white;
  }
}

button.button, a.button {
  cursor: pointer;
  font-family:"InputMonoCompressed Light";
  text-transform: uppercase;
  color: #FFF;
  text-decoration: none;
  padding: 10px;
  font-size: 16px;
  background: #000;
  border: 2px solid #FFFFFF;
  transition: 0.1s all ease-in-out;

  img {
    transition: 0.1s all ease-in-out;
  }
  
  &:focus {outline:0;}
  
  &:hover{
    color: #000;
    background-color: #FFF;
  }

  &.primary {
    color: #000;
    background-color: #FFF;

    &:hover {
      color: #FFF;
      background: #000;
      border: 2px solid #FFFFFF;     
    }
  }

  &.plain {
    border: 0;
    padding: 0;
    background: transparent;

    &.alt {
      color: #faff33;
    }

    img {
      display: block;
    }

    &:hover {
      color: inherit;
      background-color: transparent;

      img {
        opacity: 0.75;
      }
    }
  }

  &.top-left {
    position: absolute;
    top: 30px;
    left: 30px;

    &.fixed {
      position: fixed;
    }
  }

  &.top-right {
    position: absolute;
    top: 30px;
    right: 30px;

    &.fixed {
      position: fixed;
    }
  }

  &.bottom-right {
    position: absolute;
    bottom: 30px;
    right: 30px;

    &.fixed {
      position: fixed;
    }
  }

  &.bottom-left {
    position: absolute;
    bottom: 30px;
    left: 30px;

    &.fixed {
      position: fixed;
    }
  }

  &.bottom-center {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);

    &.fixed {
      position: fixed;
    }
  }

  &:disabled {
    background-color: #151515;
    border-color: #151515;
    pointer-events: none;
  }
}

div.full-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 9999;
  background: black;
  @include tablet-down {
    height: 100vh;
    // position: absolute;
    // height: auto;
    // display: block;
    // min-height: 100vh;
    // height: 200vh;
    overflow-y:scroll;
  }
}