.sub-monitor-small-holder{
  color: #fff;
  font-size: 12px;
  text-align: center;
  span{
    display: block;
    margin-bottom: 10px;
  }
}
.sub-monitor-small{
  width: 45px;
  height: 45px;
  display: block;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid #FFF;
  position: relative;
  .sub-monitor-fill{
    position: absolute;
    display: block;
    bottom: 0px;
    height: 0%;
    width: 100%;
    background: #fff;
  }
}