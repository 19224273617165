$mobile-width: 480px;
$tablet-width: 768px;
$desktop-width: 1080px;
$laptop-width-13: 1280px;
$laptop-width-15: 1440px;
$desktop-width-27: 2560px;

@mixin mobile {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}
@mixin mobile-down {
 @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin mobile-up {
  @media (min-width: #{$mobile-width + 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin tablet-portrait {
  @media (width: #{$tablet-width}) {
    @content;
  }
}

@mixin tablet-up {
  @media (min-width: #{$tablet-width + 1px}) {
    @content;
  }
}

@mixin tablet-down {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width + 1px}) {
    @content;
  }
}

@mixin desktop-up {
  @media (min-width: #{$desktop-width + 1px}) {
    @content;
  }
}

@mixin laptop-13 {
  @media (max-width: #{$laptop-width-13 + 1px}) {
    @content;
  }
}

@mixin dark {
  @media (prefers-color-scheme: dark) {
    @content;
  }
}