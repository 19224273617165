#multiple-choice{
  display: flex;
  z-index: 200;
  position: absolute;
  top: 30px;
  color: #fff;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100vw;
  height: 96vh;
  // background: #000;

  @include tablet-down {
    position: relative;
    top: auto;
    flex-direction: column;
    height: auto;
  }

  #question-holder{
    pointer-events: none;
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content:center;
    // transform-origin: 50%;
    // transform: scale(0.5);

    @include tablet-down {
      width: 100%;
    }

    #question{
      margin: 0px auto;
      border-radius: 50%;
      border: 2px solid #FFF;
      width: 40vh;
      height: 40vh;
      display: flex;
      justify-content: center;
      align-items:center;
      text-align: center;
      font-size: 32px;
      color: #fff;
      margin-bottom: 80px;
      padding: 90px;
      text-transform: none;

      @include tablet-down {
        width: calc(90vw - 30px);
        height: calc(90vw - 30px);
        padding: 15px;
        font-size: 24px;
      }
    }
    svg{
      position: absolute;
      margin: 0px auto;
      display: block;
      width: 600px;
      top: 12vh;
      // margin-top: -92px;
      // margin-left: -25px;
      // width: 20vh;
      // height: 20vh;
      #multi-time-circle{
        transform: rotate(-90deg);
        transform-origin: center;
        stroke-dasharray: 3000;
        stroke-dashoffset: 3000;
      }
    }

    #sub-question{
      text-align: center;
      color:#707070;
    }
  }
  #answers-holder{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 40%;

    @include tablet-down {
      width: 90%;
      margin-bottom: 30px;
    }

    span{
      text-transform: uppercase;
      font-size: 16px;
      color: #707070;
      display: block;
      margin-bottom: 30px;
    }
    button {
      margin-top: 15px;
      align-self: flex-start;
    }
    .answer {
      cursor: pointer;
      // width: 80%;
      // height: 20%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items:center;
      text-align: center;
      text-transform: none;
      margin-bottom: 30px;

      &.correct {
        border: 2px solid white;
      }

      svg {
        display: block;
        width: 100%;
        height: 100%;
      }

      &.selected {
        svg rect {
          stroke: #fff;
        }
      }

      &:hover:not(.selected) {
        svg rect {
          stroke: #707070;
        }
      }
    }
  }

}