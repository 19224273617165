body {
  overflow: auto;
  height: auto;
}

#how-to-page {
  color: white;
  max-width: 800px;
  margin: 60px auto;
  @include tablet-down {
    margin-top: 70px;
    // height: 100vh;
    // overflow-y:scroll;
    // padding-bottom: 40px;
    // height: auto;
    // display: block;
    // position: absolute;
    // margin-bottom: 100px;
  }

  h2 {
    font-size: 32px;
    margin: 0 0 60px;
    text-align: center;
  }

  h3 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 30px;
  }

  section {
    max-width: 1100px;
    margin: 60px auto 120px;
    @include tablet-down {
      display: block;
    }
  }

  img {
    max-width: 100%;
    display: block;
    margin: 0 auto 60px;
  }

  p {
    text-transform: none;
    max-width: 600px;
    margin: 0 auto 30px;

    @include tablet-down {
      padding: 0 30px;
    }
  }
}