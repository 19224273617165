#about-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 90vh;
  max-width: 600px;
  margin: 0 auto;
  color: white;
  @include mobile-down {
    // position: absolute;
    padding-top: 105px;
    padding-bottom: 140px;
    // height: 100%;
    // padding-bottom: 40px;
  }

  h2 {
    font-size: 32px;
    margin: 0 0 30px;
    // @include tablet-down {
    //   margin-top: 120px;
    // }
      
  }

  p {
    text-transform: none;
    margin: 0 0 30px;

    @include tablet-down {
      padding: 0 30px;
    }
  }
}