.monitor-holder{
  width: 175px;
  height: 175px;
  display: block;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid #FFF;
  margin-top: 40px;
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  @include tablet-down {
    width: 150px;
    height: 150px;
  }

  .monitor-lable{
    position: relative;
    font-size: 12px;
    // text-indent: 10px;
    line-height: 40px;
    // top: 40px;
    z-index: 10;
    width: 100%;
    height: 30px;
    text-align: center;
    color:#FFF;
    // background-color: #000;
  }
  canvas{
    position: relative;
    top: 0px;
    z-index: 1;
    left: -175px;
  }
}