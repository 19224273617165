body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@font-face {
  font-family: "InputMonoCompressed Light";
  src: url(/static/media/InputMonoCompressed-Light.b00fcacf.woff2) format("woff2"), url(/static/media/InputMonoCompressed-Light.66294dc5.woff);
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: "Input Mono Compressed";
  src: url(/static/media/InputMonoCompressed-Light.b00fcacf.woff2) format("woff2"), url(/static/media/InputMonoCompressed-Light.66294dc5.woff);
  font-style: normal;
  font-weight: 400; }

body {
  display: block;
  widows: 100vw;
  height: 100vh;
  overflow: hidden;
  /* font-family: Arial, Helvetica, sans-serif; */
  /* font-family: 'dogicabold', Arial, Helvetica, sans-serif; */
  background-color: black;
  color: white;
  font-family: InputMonoCompressed Light, sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 1.5;
  /* padding: 30px; */
  overflow-x: hidden !important; }

li {
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none; }

#footer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  color: #FFFFFF;
  width: 96%;
  margin: 20px auto;
  margin-top: 40px;
  font-size: 12px; }

#footer a {
  padding-top: 5px;
  display: block;
  color: #45F8ED; }

#footer img {
  width: 125px;
  height: 28px; }

div.App div.background-video {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.05; }
  div.App div.background-video video {
    max-width: 100vw;
    max-height: 100vh;
    display: block; }

h1, h2, h3, h4, h5 {
  font-weight: normal; }

p {
  line-height: 1.5; }
  p a {
    color: white; }

button.button, a.button {
  cursor: pointer;
  font-family: "InputMonoCompressed Light";
  text-transform: uppercase;
  color: #FFF;
  text-decoration: none;
  padding: 10px;
  font-size: 16px;
  background: #000;
  border: 2px solid #FFFFFF;
  transition: 0.1s all ease-in-out; }
  button.button img, a.button img {
    transition: 0.1s all ease-in-out; }
  button.button:focus, a.button:focus {
    outline: 0; }
  button.button:hover, a.button:hover {
    color: #000;
    background-color: #FFF; }
  button.button.primary, a.button.primary {
    color: #000;
    background-color: #FFF; }
    button.button.primary:hover, a.button.primary:hover {
      color: #FFF;
      background: #000;
      border: 2px solid #FFFFFF; }
  button.button.plain, a.button.plain {
    border: 0;
    padding: 0;
    background: transparent; }
    button.button.plain.alt, a.button.plain.alt {
      color: #faff33; }
    button.button.plain img, a.button.plain img {
      display: block; }
    button.button.plain:hover, a.button.plain:hover {
      color: inherit;
      background-color: transparent; }
      button.button.plain:hover img, a.button.plain:hover img {
        opacity: 0.75; }
  button.button.top-left, a.button.top-left {
    position: absolute;
    top: 30px;
    left: 30px; }
    button.button.top-left.fixed, a.button.top-left.fixed {
      position: fixed; }
  button.button.top-right, a.button.top-right {
    position: absolute;
    top: 30px;
    right: 30px; }
    button.button.top-right.fixed, a.button.top-right.fixed {
      position: fixed; }
  button.button.bottom-right, a.button.bottom-right {
    position: absolute;
    bottom: 30px;
    right: 30px; }
    button.button.bottom-right.fixed, a.button.bottom-right.fixed {
      position: fixed; }
  button.button.bottom-left, a.button.bottom-left {
    position: absolute;
    bottom: 30px;
    left: 30px; }
    button.button.bottom-left.fixed, a.button.bottom-left.fixed {
      position: fixed; }
  button.button.bottom-center, a.button.bottom-center {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%); }
    button.button.bottom-center.fixed, a.button.bottom-center.fixed {
      position: fixed; }
  button.button:disabled, a.button:disabled {
    background-color: #151515;
    border-color: #151515;
    pointer-events: none; }

div.full-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 9999;
  background: black; }
  @media (max-width: 1079px) {
    div.full-modal {
      height: 100vh;
      overflow-y: scroll; } }

#home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  color: #FFF;
  font-family: "InputMonoCompressed Light"; }
  @media (max-width: 1079px) {
    #home-page {
      overflow: hidden; } }
  #home-page #home-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;
    position: absolute; }
    #home-page #home-top #address {
      padding: 30px; }
      #home-page #home-top #address span {
        display: block; }
        #home-page #home-top #address span.alt {
          padding-bottom: 15px; }
    #home-page #home-top #account-nav {
      padding: 30px;
      width: 220px;
      color: #606060;
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
  #home-page #home-center {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: auto; }
    #home-page #home-center h1, #home-page #home-center h2 {
      text-align: center;
      font-style: normal;
      font-weight: normal;
      font-size: 32px; }
    #home-page #home-center h2 {
      font-size: 16px; }
    #home-page #home-center #home-menu {
      display: flex;
      flex-direction: column;
      align-items: center; }
      #home-page #home-center #home-menu #home-btns {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        margin: 45px auto 0; }
    #home-page #home-center #home-stage-types {
      margin-top: 60px; }
    #home-page #home-center #stage-list {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 600px; }
      #home-page #home-center #stage-list h2 {
        text-align: center;
        font-size: 16px; }
      #home-page #home-center #stage-list .stage-type {
        margin-top: 20px;
        text-align: center; }
        #home-page #home-center #stage-list .stage-type .stage-circle {
          width: 135px;
          height: 135px;
          border-radius: 50%;
          border: 1px solid #FFF;
          display: block;
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center; }

#about-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 90vh;
  max-width: 600px;
  margin: 0 auto;
  color: white; }
  @media (max-width: 767px) {
    #about-page {
      padding-top: 105px;
      padding-bottom: 140px; } }
  #about-page h2 {
    font-size: 32px;
    margin: 0 0 30px; }
  #about-page p {
    text-transform: none;
    margin: 0 0 30px; }
    @media (max-width: 1079px) {
      #about-page p {
        padding: 0 30px; } }

body {
  overflow: auto;
  height: auto; }

#how-to-page {
  color: white;
  max-width: 800px;
  margin: 60px auto; }
  @media (max-width: 1079px) {
    #how-to-page {
      margin-top: 70px; } }
  #how-to-page h2 {
    font-size: 32px;
    margin: 0 0 60px;
    text-align: center; }
  #how-to-page h3 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 30px; }
  #how-to-page section {
    max-width: 1100px;
    margin: 60px auto 120px; }
    @media (max-width: 1079px) {
      #how-to-page section {
        display: block; } }
  #how-to-page img {
    max-width: 100%;
    display: block;
    margin: 0 auto 60px; }
  #how-to-page p {
    text-transform: none;
    max-width: 600px;
    margin: 0 auto 30px; }
    @media (max-width: 1079px) {
      #how-to-page p {
        padding: 0 30px; } }

.blob-legend {
  color: white;
  font-size: 12px;
  position: absolute;
  width: calc(100vh * 0.3 * 2 - 15px);
  height: calc(100vh * 0.3 * 2 - 15px);
  border-radius: 100%;
  top: calc(10vh + 50px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  @media (max-width: 1079px) {
    .blob-legend {
      width: 100vw;
      height: 100vw;
      top: 0px;
      padding-top: 20px;
      border-radius: 0px; } }
  .blob-legend .row {
    display: flex;
    width: 75%;
    padding: 0; }
    @media (max-width: 1281px) {
      .blob-legend .row {
        width: 60%; } }
    @media (max-width: 1079px) {
      .blob-legend .row {
        width: 90%; } }
    .blob-legend .row.spacer {
      margin-top: 15px; }
    .blob-legend .row .title {
      color: #707070;
      width: 20%; }
      @media (max-width: 1281px) {
        .blob-legend .row .title {
          width: 25%; } }
    .blob-legend .row .description {
      width: 80%; }
      @media (max-width: 1281px) {
        .blob-legend .row .description {
          width: 75%; } }
  .blob-legend button.button {
    margin-right: auto;
    position: relative;
    z-index: 900; }

button.toggle-legend {
  z-index: 500;
  position: absolute;
  bottom: calc(30px - 2vh);
  right: 30px; }
  @media (max-width: 1079px) {
    button.toggle-legend {
      width: 100px;
      left: 30px;
      bottom: auto;
      margin-top: -44px; } }

div.blob-debug {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 30px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 900;
  font-size: 14px;
  color: #faff33; }
  div.blob-debug div.row {
    display: flex;
    justify-content: space-between; }
    div.blob-debug div.row p:first-child {
      padding-right: 30px; }
  div.blob-debug p {
    margin: 0 0 3px; }
    div.blob-debug p:last-child {
      margin: 0; }

div.centered {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: white;
  text-transform: uppercase; }

#bonus-level {
  width: 100%;
  height: 100%;
  position: absolute;
  display: none;
  z-index: 300;
  color: #fff;
  top: 0px;
  text-align: center;
  text-transform: uppercase;
  flex-direction: column;
  justify-content: space-around; }
  @media (max-width: 1079px) {
    #bonus-level {
      position: relative; } }
  #bonus-level canvas {
    margin: 0 auto;
    width: 600px;
    height: 600px; }
    @media (max-width: 1079px) {
      #bonus-level canvas {
        width: 100%;
        height: 100%; } }
  #bonus-level #instructions {
    color: #707070; }
  #bonus-level #centerContent {
    position: absolute;
    width: 300px;
    top: 50%;
    left: calc(50% - 150px);
    display: flex;
    flex-direction: column;
    align-items: center; }
    @media (max-width: 1079px) {
      #bonus-level #centerContent {
        position: relative;
        margin: 60px 0; } }
    #bonus-level #centerContent .points div {
      font-size: 16px;
      color: #707070;
      width: 175px;
      text-align: left;
      margin-bottom: 10px; }
    #bonus-level #centerContent .points span {
      color: #FFF;
      float: right;
      text-align: right; }
    #bonus-level #centerContent .points button {
      margin: 50px auto; }
    #bonus-level #centerContent button:focus {
      outline: 0; }
    #bonus-level #centerContent button {
      cursor: pointer;
      text-transform: uppercase;
      display: block;
      padding: 10px;
      border: none; }

.end-level {
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  z-index: 200;
  color: #fff;
  top: 0px;
  text-align: center;
  text-transform: uppercase; }
  @media (max-width: 1079px) {
    .end-level {
      position: relative;
      width: 90%;
      margin: 30px auto; } }
  .end-level .end-wrapper {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly; }
  .end-level #sample_holder {
    width: 427px;
    height: 300px;
    display: block;
    margin-left: -27px; }
    @media (max-width: 1079px) {
      .end-level #sample_holder {
        width: 100%;
        height: 300px;
        margin-left: 0; } }
    .end-level #sample_holder #chart-info {
      position: absolute; }
      .end-level #sample_holder #chart-info #verticle-txt {
        position: absolute;
        color: #707070;
        margin-left: -170px;
        margin-top: calc(300px - (300px * 0.3428));
        transform: rotate(-90deg);
        transform-origin: 50%;
        width: 300px;
        font-size: 12px; }
      .end-level #sample_holder #chart-info #center-line {
        width: 400px;
        height: 1px;
        position: absolute;
        margin-top: 152px;
        margin-left: 2px;
        border-top: 0.5px solid #707070; }
      .end-level #sample_holder #chart-info #chart-background {
        border: 2px solid white;
        z-index: 100;
        width: 400px;
        height: 300px;
        display: flex;
        flex-direction: row;
        text-align: center; }
        .end-level #sample_holder #chart-info #chart-background div {
          width: 50%;
          font-size: 12px;
          padding: 15px; }
  .end-level .end-status {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    @media (max-width: 1079px) {
      .end-level .end-status {
        width: 100%; } }
    .end-level .end-status h2 {
      font-size: 32px;
      color: #D43C3C;
      margin: 5px 0 30px;
      font-weight: normal; }
      .end-level .end-status h2.correct {
        color: #3FD43C; }
  .end-level #blob-guess {
    margin: 0 0 30px; }
  .end-level #end-points {
    margin: 15px 0;
    display: flex;
    align-items: center; }
    @media (max-width: 1079px) {
      .end-level #end-points {
        flex-direction: column; } }
    .end-level #end-points > div {
      font-size: 16px;
      color: #707070;
      text-align: left;
      margin: 0 30px; }
      @media (max-width: 1079px) {
        .end-level #end-points > div {
          margin: 0 0 10px 0; } }
      .end-level #end-points > div span {
        color: #FFF;
        float: right;
        text-align: right;
        padding-left: 10px; }
    .end-level #end-points .end-points-label {
      font-size: 16px; }
  .end-level #end-qa {
    display: flex;
    flex-direction: column;
    align-items: center; }
    @media (max-width: 1079px) {
      .end-level #end-qa {
        width: 100vw; } }
  .end-level .end-qa-holder {
    margin-bottom: 40px; }
    .end-level .end-qa-holder > span {
      display: block;
      padding-bottom: 10px; }
  .end-level #end-q {
    font-size: 32px;
    text-transform: none; }
  .end-level .end-a-holder {
    width: 450px;
    margin: 0 30px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 1079px) {
      .end-level .end-a-holder {
        width: 100%;
        margin: 0 0 30px 0; } }
    .end-level .end-a-holder > div {
      border: solid #fff 2px; }
      @media (max-width: 1079px) {
        .end-level .end-a-holder > div {
          max-width: 90%; } }
    .end-level .end-a-holder > span {
      display: block;
      padding-bottom: 10px; }
    .end-level .end-a-holder svg {
      display: block;
      max-width: 100%;
      text-transform: none; }
  .end-level .end-a-holder rect {
    display: none; }
  @media (max-width: 1079px) {
    .end-level #qa-row {
      width: 100vw; } }
  .end-level #qa-row.incorrect {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between; }
    @media (max-width: 1079px) {
      .end-level #qa-row.incorrect {
        flex-direction: column;
        width: 100vw; } }
    .end-level #qa-row.incorrect #end-a {
      border-color: #151515; }
  .end-level .game-over {
    font-size: 36px;
    display: none; }
  .end-level #end-lives span {
    color: #707070; }
  .end-level #lives {
    display: inline-block;
    padding-left: 10px; }
    .end-level #lives div {
      vertical-align: middle;
      display: inline-block;
      margin: -3px 0 0 10px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 2px solid #FFFFFF;
      background: #FFF; }
      .end-level #lives div.dead {
        background: none; }

.game-header {
  position: relative;
  z-index: 990; }
  .game-header .game-info {
    width: calc(100% - 30px);
    display: flex;
    color: #ffffff;
    justify-content: space-between;
    font-size: 18px; }
    .game-header .game-info .menu-btn {
      width: 30%;
      margin-left: 30px; }
      .game-header .game-info .menu-btn button.mute {
        margin-left: 30px;
        position: relative;
        top: 4px; }
        @media (max-width: 1079px) {
          .game-header .game-info .menu-btn button.mute {
            margin-left: 10px; } }
    .game-header .game-info .menu {
      position: absolute;
      z-index: 950;
      color: #000; }
      .game-header .game-info .menu button {
        display: block;
        width: 100%;
        text-align: left; }
        .game-header .game-info .menu button:hover {
          background-color: #000;
          color: #FFF; }
    .game-header .game-info .level-number {
      width: 40%;
      text-align: center;
      font-size: 16px;
      text-transform: uppercase; }
    .game-header .game-info .score-lives {
      width: 30%;
      height: 50px; }
      .game-header .game-info .score-lives .score {
        font-size: 16px;
        clear: both;
        float: right; }
    .game-header .game-info .lives {
      position: absolute;
      right: 30px;
      top: 20px;
      display: block; }
      .game-header .game-info .lives div {
        display: inline-block;
        margin-left: 10px;
        margin-top: 10px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 2px solid #FFFFFF;
        background: #FFF; }
      .game-header .game-info .lives .dead {
        background: none; }

.game-holder {
  display: block;
  width: 100vw;
  height: 96vh;
  overflow: hidden;
  border-top: none;
  position: relative;
  top: 20px; }
  @media (max-width: 1079px) {
    .game-holder {
      padding: 0px;
      margin: 0px;
      overflow-y: auto;
      overflow-x: hidden;
      width: 100vw;
      height: auto; } }

#blob-holder {
  width: 100%;
  position: absolute;
  display: block;
  z-index: 1; }
  @media (max-width: 1079px) {
    #blob-holder {
      position: relative;
      height: 60vh;
      display: flex; } }
  @media (max-width: 480px) {
    #blob-holder {
      margin-top: -10vh; } }
  #blob-holder #background-animation {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%); }
    @media (max-width: 1079px) {
      #blob-holder #background-animation {
        height: 60vh;
        display: flex;
        flex-direction: column;
        justify-content: center; } }
  #blob-holder #time-holder {
    position: relative;
    z-index: 500;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 80vh;
    transform: rotate(-90deg);
    transform-origin: center; }
    @media (max-width: 1079px) {
      #blob-holder #time-holder {
        height: 60vh; } }
    #blob-holder #time-holder svg {
      display: block; }
  #blob-holder #blob-stats {
    z-index: 600;
    position: absolute;
    bottom: 10%;
    right: calc(30% - 125px);
    display: flex;
    flex-direction: column;
    color: #fff;
    width: 250px;
    font-size: 12px; }
    @media (max-width: 1281px) {
      #blob-holder #blob-stats {
        right: calc(30% - 92.5px);
        bottom: 15%;
        font-size: 11px;
        width: 185px; } }
    @media (max-width: 1079px) {
      #blob-holder #blob-stats {
        width: 185px;
        bottom: -15px;
        right: 30px;
        left: auto; } }
    #blob-holder #blob-stats div {
      display: flex;
      flex-direction: row; }
      #blob-holder #blob-stats div .blob-label {
        width: 60px;
        color: #707070; }
        @media (max-width: 1281px) {
          #blob-holder #blob-stats div .blob-label {
            width: 50px; } }
      #blob-holder #blob-stats div span:nth-child(2) {
        width: 120px; }
        @media (max-width: 1281px) {
          #blob-holder #blob-stats div span:nth-child(2) {
            width: 100px; } }

#game-ui {
  position: relative;
  display: block;
  z-index: 100;
  height: 90vh;
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between; }
  @media (max-width: 1079px) {
    #game-ui {
      height: auto;
      width: 100%;
      position: static; } }
  #game-ui #monitors-left, #game-ui #monitors-right {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center; }
    @media (max-width: 1079px) {
      #game-ui #monitors-left, #game-ui #monitors-right {
        width: 50%;
        margin-top: 110px;
        margin-bottom: 120px; } }
  #game-ui #center-ui {
    position: relative;
    height: 100%;
    width: 40%;
    max-width: 500px;
    display: block; }
    @media (max-width: 1079px) {
      #game-ui #center-ui {
        position: static;
        width: 0; } }
    #game-ui #center-ui #alert-monitors {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly; }
      @media (max-width: 1079px) {
        #game-ui #center-ui #alert-monitors {
          position: relative;
          width: 98vw;
          margin-left: -49vw;
          margin-top: 43px; } }
    #game-ui #center-ui #points {
      color: #ffffff;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      font-size: 18px;
      font-weight: 500;
      text-align: center; }
      #game-ui #center-ui #points span {
        display: block;
        font-weight: 900;
        font-size: 40px;
        line-height: 37px; }
    #game-ui #center-ui #buttons {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      position: absolute;
      bottom: 20px;
      margin: 0px auto; }
      @media (max-width: 1079px) {
        #game-ui #center-ui #buttons {
          bottom: 90px;
          left: 0;
          bottom: 0;
          z-index: 900;
          position: fixed;
          padding-top: 20px;
          background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), black); }
          #game-ui #center-ui #buttons #no {
            margin-left: 15px; } }
      #game-ui #center-ui #buttons span {
        display: block;
        color: #FFF;
        text-transform: uppercase;
        text-align: center; }
      #game-ui #center-ui #buttons button:focus {
        outline: 0; }
      #game-ui #center-ui #buttons button {
        border-radius: 50%;
        width: 53px;
        height: 53px;
        margin: 0px auto;
        display: block;
        cursor: pointer;
        margin-top: 10px;
        margin-bottom: 20px;
        text-transform: uppercase;
        border: none; }
      #game-ui #center-ui #buttons #yes-btn {
        background-color: #3FD43C;
        box-shadow: 4px 4px 1px #195318;
        position: relative; }
        #game-ui #center-ui #buttons #yes-btn:active {
          top: 4px;
          left: 4px;
          box-shadow: 1px 1px 1px #195318; }
      #game-ui #center-ui #buttons #no-btn {
        background-color: #D53C3C;
        box-shadow: 4px 4px 1px #4d1717;
        position: relative; }
        #game-ui #center-ui #buttons #no-btn:active {
          top: 4px;
          left: 4px;
          box-shadow: 1px 1px 1px #4d1717; }

#game-over {
  color: white;
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  @media (max-width: 1079px) {
    #game-over {
      height: auto; } }
  #game-over header {
    margin-bottom: 60px; }
  #game-over h1 {
    font-size: 48px; }
  #game-over h2 {
    font-size: 32px;
    margin: 0; }
    #game-over h2.large {
      font-size: 40px; }
  #game-over h3 {
    font-size: 24px;
    margin: 0 0 15px; }
    #game-over h3 span {
      text-transform: none; }
  #game-over section {
    margin: 0 0 60px;
    width: 80%;
    display: flex;
    justify-content: space-around; }
    @media (max-width: 1079px) {
      #game-over section {
        flex-direction: column; } }
    #game-over section.totals {
      justify-content: center; }
  #game-over .stat {
    width: 30%;
    display: flex;
    flex-direction: column; }
    @media (max-width: 1079px) {
      #game-over .stat {
        width: 100%;
        margin-bottom: 60px; }
        #game-over .stat:last-child {
          margin-bottom: 0; } }
    #game-over .stat p.points {
      color: white;
      margin-top: auto;
      padding-top: 30px; }
      #game-over .stat p.points span {
        color: #707070; }
  #game-over .stages {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; }
    #game-over .stages .circle {
      margin: 10px;
      width: 42px;
      height: 42px;
      border-radius: 100%;
      background: #3FD43C; }
      #game-over .stages .circle.status-false {
        background: #D43C3C; }
      #game-over .stages .circle.bonus {
        background: #49B1EB;
        color: black;
        line-height: 42px; }

#multiple-choice {
  display: flex;
  z-index: 200;
  position: absolute;
  top: 30px;
  color: #fff;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100vw;
  height: 96vh; }
  @media (max-width: 1079px) {
    #multiple-choice {
      position: relative;
      top: auto;
      flex-direction: column;
      height: auto; } }
  #multiple-choice #question-holder {
    pointer-events: none;
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    @media (max-width: 1079px) {
      #multiple-choice #question-holder {
        width: 100%; } }
    #multiple-choice #question-holder #question {
      margin: 0px auto;
      border-radius: 50%;
      border: 2px solid #FFF;
      width: 40vh;
      height: 40vh;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 32px;
      color: #fff;
      margin-bottom: 80px;
      padding: 90px;
      text-transform: none; }
      @media (max-width: 1079px) {
        #multiple-choice #question-holder #question {
          width: calc(90vw - 30px);
          height: calc(90vw - 30px);
          padding: 15px;
          font-size: 24px; } }
    #multiple-choice #question-holder svg {
      position: absolute;
      margin: 0px auto;
      display: block;
      width: 600px;
      top: 12vh; }
      #multiple-choice #question-holder svg #multi-time-circle {
        transform: rotate(-90deg);
        transform-origin: center;
        stroke-dasharray: 3000;
        stroke-dashoffset: 3000; }
    #multiple-choice #question-holder #sub-question {
      text-align: center;
      color: #707070; }
  #multiple-choice #answers-holder {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 40%; }
    @media (max-width: 1079px) {
      #multiple-choice #answers-holder {
        width: 90%;
        margin-bottom: 30px; } }
    #multiple-choice #answers-holder span {
      text-transform: uppercase;
      font-size: 16px;
      color: #707070;
      display: block;
      margin-bottom: 30px; }
    #multiple-choice #answers-holder button {
      margin-top: 15px;
      align-self: flex-start; }
    #multiple-choice #answers-holder .answer {
      cursor: pointer;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      text-transform: none;
      margin-bottom: 30px; }
      #multiple-choice #answers-holder .answer.correct {
        border: 2px solid white; }
      #multiple-choice #answers-holder .answer svg {
        display: block;
        width: 100%;
        height: 100%; }
      #multiple-choice #answers-holder .answer.selected svg rect {
        stroke: #fff; }
      #multiple-choice #answers-holder .answer:hover:not(.selected) svg rect {
        stroke: #707070; }

.monitor-holder {
  width: 175px;
  height: 175px;
  display: block;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid #FFF;
  margin-top: 40px;
  -webkit-mask-image: -webkit-radial-gradient(white, black); }
  @media (max-width: 1079px) {
    .monitor-holder {
      width: 150px;
      height: 150px; } }
  .monitor-holder .monitor-lable {
    position: relative;
    font-size: 12px;
    line-height: 40px;
    z-index: 10;
    width: 100%;
    height: 30px;
    text-align: center;
    color: #FFF; }
  .monitor-holder canvas {
    position: relative;
    top: 0px;
    z-index: 1;
    left: -175px; }

.sub-monitor-small-holder {
  color: #fff;
  font-size: 12px;
  text-align: center; }
  .sub-monitor-small-holder span {
    display: block;
    margin-bottom: 10px; }

.sub-monitor-small {
  width: 45px;
  height: 45px;
  display: block;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid #FFF;
  position: relative; }
  .sub-monitor-small .sub-monitor-fill {
    position: absolute;
    display: block;
    bottom: 0px;
    height: 0%;
    width: 100%;
    background: #fff; }

