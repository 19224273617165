.end-level{
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  z-index: 200;
  color: #fff;
  top: 0px;
  text-align: center;
  text-transform: uppercase;

  @include tablet-down {
    position: relative;
    width: 90%;
    margin: 30px auto;
  }
  .end-wrapper{
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  #sample_holder{
    width: 427px;
    height: 300px;
    display: block;
    margin-left: -27px;

    @include tablet-down {
      width: 100%;
      height: 300px;
      margin-left: 0;
    }
    // border: 2px solid white;
    #chart-info{
      // display: flex;
      // flex-direction: row;
      position: absolute;
      // margin-left: -50px;
      // width: 450px;
      // height: 300px;
      #verticle-txt{
        position: absolute;
        color: #707070;
        // width: 50px;
        // writing-mode:vertical-lr;
        // text-orientation: mixed;
        // direction:rtl
        margin-left: -170px;
        margin-top: calc(300px - (300px * 0.3428));
        transform: rotate(-90deg);
	      transform-origin: 50%;
        width: 300px;
        font-size: 12px;
        // height: 400px;
        // float: right;
      }
      #center-line{
        width: 400px;
        height: 1px;
        position: absolute;
        margin-top: 152px;
        margin-left: 2px;
        // border-top: 1px solid transparent;
        // border-image: url("../images/dashed_border.png") 2 stretch;
        border-top: 0.5px solid #707070;
      }
      #chart-background{
        border: 2px solid white;
        
        z-index: 100;
        width: 400px;
        height: 300px;
        display: flex;
        flex-direction: row;
        text-align: center;

        div{
          width: 50%;
          font-size: 12px;
          // border: 10px solid transparent;
          // border: 1px dashed #707070;
          padding: 15px;
          
        }
      }
    }
  }
  .end-status {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include tablet-down {
      width: 100%;
    }

    h2 {
      font-size: 32px;
      color: #D43C3C;
      margin: 5px 0 30px;
      font-weight: normal;
    
      &.correct{
        color: #3FD43C;
      }
    }
  }

  #blob-guess {
    margin: 0 0 30px;
  }
  #end-points{
    margin: 15px 0;
    display: flex;
    align-items: center;

    @include tablet-down {
      flex-direction: column;
    }

    & > div{
      font-size: 16px;
      color: #707070;
      text-align: left;
      margin: 0 30px;

      @include tablet-down {
        margin: 0 0 10px 0;
      }

      span{
        color: #FFF;
        float: right;
        text-align: right;
        padding-left: 10px;
      }
    }
    .end-points-label{
      font-size: 16px;
    }
    // .end-points-total-label{
    //   // font-size: 24px;
    // }
  }
  #end-qa{
    display: flex;
    flex-direction: column;
    align-items: center;

    @include tablet-down {
      width: 100vw;
    }
  }
  .end-qa-holder {
    margin-bottom: 40px;

    & > span {
      display: block;
      padding-bottom: 10px;
    }
  }
  #end-q {
    font-size: 32px;
    text-transform: none;
  }
  .end-a-holder{
    width: 450px;
    margin: 0 30px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @include tablet-down {
      width: 100%;
      margin: 0 0 30px 0;
    }

    & > div {
      border: solid #fff 2px;

      @include tablet-down {
        max-width: 90%;
      }
    }

    & > span {
      display: block;
      padding-bottom: 10px;
    }

    svg {
      display: block;
      max-width: 100%;
      text-transform: none;
    }

  }
  .end-a-holder{
    rect{
      display: none;
    }
  }
  #qa-row {
    @include tablet-down {
      width: 100vw;
    }
    
    &.incorrect{
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;

      @include tablet-down {
        flex-direction: column;
        width: 100vw;
      }

      #end-a {
        border-color: #151515;
      }
    }

  }
  .game-over{
    font-size: 36px;
    display: none;
  }

  #end-lives{
    span{
      color: #707070;
    }
  }
  #lives{
    // position: absolute;
    // right: 0px;
    // top: 20px;
    display: inline-block;
    padding-left: 10px;

    div{
      vertical-align: middle;
      display: inline-block;
      margin: -3px 0 0 10px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 2px solid #FFFFFF;
      background: #FFF;
      &.dead{
        background: none;
      }
    }
  }


}