#home-page{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  color: #FFF;
  font-family:"InputMonoCompressed Light";
  @include tablet-down {
    overflow:hidden;
  }

  #home-top{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;
    position: absolute;

    #address{
      padding: 30px;
      span{
        display: block;
        
        &.alt {
          padding-bottom: 15px;
        }
      }
    }
    #account-nav{
      padding: 30px;
      width: 220px;
      color: #606060;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  #home-center{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: auto;

    h1, h2{
      text-align: center;
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
    }
    h2{
      font-size: 16px;
    }
    #home-menu{
      display: flex;
      flex-direction: column;
      align-items: center;

      #home-btns{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        margin: 45px auto 0;
      }
    }
    
    #home-stage-types {
      margin-top: 60px;
    }
  
    #stage-list{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 600px;

      h2{
        text-align: center;
        font-size: 16px;
        
      }
      .stage-type{
        margin-top: 20px;
        text-align: center;
        .stage-circle{
          width: 135px;
          height: 135px;
          border-radius: 50%;
          border: 1px solid #FFF;
          display: block;
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }


}