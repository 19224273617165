.game-holder{
  display: block;
  width: 100vw;
  height: 96vh;
  // margin-top: 20px;
  overflow: hidden;
  // border: 1px solid #ffffff;
  border-top: none;
  position: relative;
  top: 20px;

  @include tablet-down {
    padding: 0px;
    margin: 0px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100vw;
    height: auto;
  }
}

  #blob-holder{
    width: 100%;
    // overflow: hidden;
    position: absolute;
    display: block;
    z-index: 1;

    @include tablet-down {
      position: relative;
      height: 60vh;
      display: flex;
    }
    @include mobile{
      margin-top: -10vh;
    }

    #background-animation{
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      @include tablet-down {
        height: 60vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    #time-holder{
      position: relative;
      z-index: 500;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // margin: 100px auto;
      // width: 600px;
      // height: 600px;
      width: 100vw;
      height: 80vh;
      transform: rotate(-90deg);
      transform-origin: center;
      @include tablet-down {
        height: 60vh;
      }
      svg{
        display: block;
      }
    }
    #blob-stats{
      z-index: 600;
      position: absolute;
      bottom: 10%;
      right: calc(30% - 125px);
      display: flex;
      flex-direction: column;
      color: #fff;
      width: 250px;
      font-size: 12px;

      @include laptop-13 {
        right: calc(30% - 92.5px);
        bottom: 15%;
        font-size: 11px;
        width: 185px;
      }
      @include tablet-down {
        width: 185px;
        bottom: -15px;
        right: 30px;
        left: auto;
      }

      div{
        display: flex;
        flex-direction: row;
        .blob-label{
          width: 60px;
          color: #707070;

          @include laptop-13 {
            width: 50px;
          }
        }
        span:nth-child(2){
          width: 120px;

          @include laptop-13 {
            width: 100px;
          }
        }
      }
    }
  }

  #game-ui{
    position: relative;
    display: block;
    z-index: 100;
    // height: 90%;
    height: 90vh;
    // height:calc(100% - 240px);
    margin: 0px auto;
    display: flex;
    flex-direction:row;
    flex-wrap: nowrap;
    justify-content: space-between;

    @include tablet-down {
      height: auto;
      width: 100%;
      position: static;
    }

    #monitors-left, #monitors-right {
      width: 30%;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include tablet-down {
        width: 50%;
        margin-top: 110px;
        margin-bottom: 120px;
      }
    }

    #center-ui{
      position: relative;
      height: 100%;
      width: 40%;
      max-width: 500px;
      display: block;
      // color: #458af8;

      @include tablet-down {
        position: static;
        width: 0;
      }

      #alert-monitors{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        @include tablet-down {
          // position: absolute;
          // // top: 90px;
          // top: 67vh;
          // left: 0;
          position: relative;
          width: 98vw;
          margin-left: -49vw;
          margin-top: 43px;
        }
        @include mobile{
          // margin-top: -10vh;
        }
      }

      // display: flex;
      // flex-direction: column;

      
// POINTS
      #points{
        color: #ffffff;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        span{
          display: block;
          font-weight: 900;
          font-size: 40px;
          line-height: 37px;
        }
      }

//BUTTONS 
      #buttons{
        width: 100%;
        // max-width: 300px;
        // // margin:auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        position: absolute;
        bottom: 20px;
        margin: 0px auto;

        @include tablet-down {
          bottom: 90px;
          left: 0;
          bottom: 0;
          z-index: 900;
          position: fixed;
          padding-top: 20px;
          // background-color: rgba(0, 0, 0, 0.8);
          background-image: linear-gradient(to bottom, rgba(0,0,0,0),  rgba(0,0,0, 0.75), rgba(0,0,0,1));
          // #yes{
          //   margin-left: 12px;
          // }
          #no{
            margin-left: 15px;
          }
        }

        span{
          display: block;
          color: #FFF;
          text-transform: uppercase;
          text-align: center;
        }
        button:focus {outline:0;}
        button{
          border-radius: 50%;
          width: 53px;
          height: 53px;
          margin: 0px auto;
          display: block;
          // background: #FFFEFE;
          // width: 244px;
          // height: 59px;
          // border-radius: 50px;
          // font-weight: 500;
          // font-size: 20px;
          // line-height: 27px;
          cursor: pointer;
          margin-top: 10px;
          margin-bottom: 20px;
          text-transform: uppercase;
          border:none;
          
        }
        #yes-btn{
          background-color: #3FD43C;
          box-shadow: 4px 4px 1px #195318;
          position: relative;

          &:active {
            top: 4px;
            left: 4px;
            box-shadow: 1px 1px 1px #195318;
          }
        }
        #no-btn{
          background-color:#D53C3C;
          box-shadow: 4px 4px 1px #4d1717;
          position: relative;

          &:active {
            top: 4px;
            left: 4px;
            box-shadow: 1px 1px 1px #4d1717;
          }
        }
        button:hover{
          // border: 4px solid #45F8ED;
        }
      }
    }
  }
