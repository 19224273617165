#bonus-level{
  width: 100%;
  height: 100%;
  position: absolute;
  display: none;
  z-index: 300;
  color: #fff;
  top: 0px;
  text-align: center;
  text-transform: uppercase;
  // display: flex;
  flex-direction: column;
  justify-content: space-around;

  @include tablet-down {
    position: relative;
  }

  canvas{
    margin: 0 auto;
    width:600px;
    height: 600px;

    @include tablet-down {
      width: 100%;
      height: 100%;
    }
  }
  #instructions{
    color: #707070;
  }
  #centerContent{
    position: absolute;
    width: 300px;
    top: 50%;
    left: calc(50% - 150px);
    display: flex;
    flex-direction: column;
    align-items: center;

    @include tablet-down {
      position: relative;
      margin: 60px 0;
    }

    .points{
      div{
        font-size: 16px;
        color: #707070;
        width: 175px;
        text-align: left;
        margin-bottom: 10px;

      }
      span{
        color: #FFF;
        float: right;
        text-align: right;
      }
      button{
        margin: 50px auto;
      }
    }

    button:focus {outline:0;}
    button{
      cursor: pointer;
      text-transform: uppercase;
      display: block;
      padding: 10px;
      border: none;
      // margin: 50px auto;
    }
  }
}