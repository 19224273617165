#game-over {
  color: white;
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include tablet-down {
    height: auto;
  }

  header {
    margin-bottom: 60px;
  }

  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 32px;
    margin: 0;

    &.large {
      font-size: 40px;
    }
  }

  h3 {
    font-size: 24px;
    margin: 0 0 15px;

    span {
      text-transform: none;
    }
  }

  section {
    margin: 0 0 60px;
    width: 80%;
    display: flex;
    justify-content: space-around;

    @include tablet-down {
      flex-direction: column;
    }

    &.totals {
      justify-content: center;
    }
  }

  .stat {
    width: 30%;
    display: flex;
    flex-direction: column;

    @include tablet-down {
      width: 100%;
      margin-bottom: 60px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    p.points {
      color: white;
      margin-top: auto;
      padding-top: 30px;

      span {
        color: #707070;
      }
    }
  }

  .stages {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .circle {
      margin: 10px;
      width: 42px;
      height: 42px;
      border-radius: 100%;
      background: #3FD43C;

      &.status-false {
        background: #D43C3C;
      }

      &.bonus {
        background: #49B1EB;
        color: black;
        line-height: 42px;
      }
    }
  }
}