.blob-legend {
  color: white;
  font-size: 12px;
  position: absolute;
  width: calc(100vh * 0.3 * 2 - 15px);
  height: calc(100vh * 0.3 * 2 - 15px);
  border-radius: 100%;
  top: calc(10vh + 50px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  // transform: translateY(calc(-50% - 4vh));
  background: rgba(0,0,0,0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  @include tablet-down {
    width: 100vw;
    height: 100vw;
    top: 0px;
    padding-top: 20px;
    border-radius: 0px;
  }
  
  .row {
    display: flex;
    width: 75%;
    padding: 0;

    @include laptop-13 {
      width: 60%;
    }

    @include tablet-down {
      width: 90%;
    }

    &.spacer {
      margin-top: 15px;
    }

    .title {
      color: #707070;
      width: 20%;

      @include laptop-13 {
        width: 25%;
      }
    }

    .description {
      width: 80%;

      @include laptop-13 {
        width: 75%;
      }
    }
  }

  button.button {
    margin-right: auto;
    position: relative;
    z-index: 900;
  }
}

button.toggle-legend {
  z-index: 500;
  position: absolute;
  bottom: calc(30px - 2vh);
  right: 30px;
  @include tablet-down {
    // left: 30px;
    // top: 55vh;
    // bottom: auto;
    width: 100px;
    left: 30px;
    bottom: auto;
    margin-top: -44px;
  }
  @include mobile{
    // margin-top: -15vh;
  }
}



div.blob-debug {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 30px;
  background: rgba(0,0,0,0.5);
  z-index: 900;
  font-size: 14px;
  color: #faff33;

  div.row {
    display: flex;
    justify-content: space-between;

    p:first-child {
      padding-right: 30px;
    }
  }

  p {
    margin: 0 0 3px;

    &:last-child {
      margin: 0;
    }
  }
}

div.centered {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: white;
  text-transform: uppercase;
}